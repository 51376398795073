import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SelectionButton from '../selectionButton';
import WidgetSearch from '../widgetSearch';
import SinglePropertyV2 from "./singleProperty-v2"
import APIs from '../../APIs/APIs';
import axios from '../../axios'
import Loader from './Loader';

const PropertyGrid = ({ offer }) => {


	const [data, setDate] = useState()
	const [status, setstatus] = useState(true)
	const [filter_data, setFilter_data] = useState()

	const [filter, setFilter] = useState(true)
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(1)

	const [city_id, setCity_id] = useState("")
	const [bedrooms, setBedrooms] = useState("")
	const [bathroom, setBathroom] = useState("")
	const [property_type, setProperty_type] = useState("")
	const [size, setSize] = useState("")
	const [keyword, setKeyword] = useState("")
	const [community, setCommunity] = useState("")
	const [sort_by, setSortBy] = useState("Default")

	const fetchTeams = () => {
		setLoading(true)
		axios.post(`${APIs.Properties}/for-${offer}/${page}/${sort_by}`, {
			city_id, community, property_type, bathroom, bedrooms, size, keyword
		}).then((res) => {
			setDate(res.data.data)
			setLoading(false)
			setstatus(res.data.status)
		}).catch(error => {
			setLoading(false)
		});
	}



	useEffect(() => {

		fetchTeams()
		window.scrollTo(0, 0)

	}, [offer, page, city_id, community, property_type, bathroom, bedrooms, sort_by])

	let number_of_listed = data?.total;

	const fetchFilterationData = () => {
		setLoading(true)
		axios.get(`${APIs.filterationData}`).then((res) => {
			setFilter_data(res.data.data)
			// setLoading(false)
		}).catch(error => {
			// setLoading(false)
			console.log(error);
		});
	}

	useEffect(() => {

		fetchFilterationData()
		window.scrollTo(0, 0)

	}, [])

	console.log(`data?.length ${sort_by}`);

	const sortItems = {

		"Price High to Low": "Price High to Low",
		"Price Low to High": "Price Low to High",
		"Newest First": "Newest First",
		"Oldest First": "Oldest First",
	}


	return (


		<div className="blog-page-area pt-5 go-top">
			<div className="container">
				<div className="row justify-content-center">
					{/* ====================Filter Section============================ */}

					<div style={{ marginTop: "-8rem" }} className="col-12">
						<div className="product-search-inner bg-main">
							{filter ? (
								<div className="row custom-gutters-20">
									<SelectionButton filterName={"All Cities"} filter_data={filter_data?.cities} value={city_id} setValue={setCity_id} f_option="" keyOrV="key" />
									<SelectionButton filterName={"All Areas"} filter_data={filter_data?.areas} value={community} setValue={setCommunity} f_option="" keyOrV="key" />
									<SelectionButton filterName={"All Bedrooms"} filter_data={filter_data?.min_bedrooms} value={bedrooms} setValue={setBedrooms} f_option="" keyOrV="value" />
									<SelectionButton filterName={"All Bathrooms"} filter_data={filter_data?.min_bathrooms} value={bathroom} setValue={setBathroom} f_option="" keyOrV="value" />
									<SelectionButton filterName={"All Property Types"} filter_data={filter_data?.property_types} value={property_type} setValue={setProperty_type} f_option="" keyOrV="key" />
									<div className="col-md-3 mt-2 mt-md-0">
										<WidgetSearch searchPlaceHolde="Min Size (sqft)" value={size} setValue={setSize} fun={fetchTeams} />
									</div>
									<div className="col-md-3 mt-2 mt-md-0">
										<WidgetSearch searchPlaceHolde="Keyword" value={keyword} setValue={setKeyword} fun={fetchTeams} />
									</div>

								</div>
							) : (
								<div className="widget widget-tags">

									<div className="tagcloud">
										<h4><Link onClick={() => setFilter(true)} style={{ color: "red" }}>Cancle</Link></h4>
									</div>

								</div>
							)}
						</div>
					</div>
					{/* ====================End Of Filter Section============================ */}
					{
						loading ? <Loader loading={loading} height="" /> :
							status ?

								<>
									<div className='col-12'>
										<div className="col-md-3 align-self-center">
											<h5>{number_of_listed} Properties</h5>
										</div>
										<div className="row custom-gutters-20">
											<SelectionButton keyOrV="key" filterName={"Sort By Default"} f_option="Default" filter_data={sortItems} value={sort_by} setValue={setSortBy} />
										</div>
									</div>
									{/* ====================Properties Section============================ */}
									<div className='col-12'>
										<div className="row">
											{
												data?.properties?.map((item, idx) => {
													return (
														<div className="col-lg-4 col-md-6">
															<SinglePropertyV2 item={item} />
														</div>
													)
												})
											}


										</div>
									</div>
									{/* ====================End Of Properties Section============================ */}

									{/* ====================Pagination Section============================ */}
									<div className="pagination-area row text-center mt-4">
										<ul className="pagination">
											<li className="page-item"><button disabled={data?.current_page == 1} onClick={() => setPage(data?.current_page > 1 ? data?.current_page - 1 : 1)} className="page-link" ><i className="la la-angle-double-left" /></button></li>
											<li className={`page-item ${page == 1 ? "active" : ""} `}><a className="page-link" onClick={() => setPage(1)}>1</a></li>
											{
												data?.total > 10 &&
												<li className={`page-item ${page == 2 ? "active" : ""} `}><button onClick={() => setPage(2)} className="page-link" >2</button></li>
											}
											{
												data?.total > 20 &&
												<li className={`page-item ${page > 2 ? "active" : ""} `}><button className="page-link" onClick={() => setPage(page + 1)}>{page + 1}</button></li>
											}
											<li className="page-item"><button disabled={data?.current_page == data?.last_page} onClick={() => setPage(data?.last_page)} className="page-link"><i className="la la-angle-double-right" /></button></li>
										</ul>
									</div>
									{/* ====================End Of Pagination Section============================ */}
								</>
								: <h5 className='mt-4 mb-4'>No data available</h5>
					}
				</div>
			</div>
		</div>
	)
}

export default PropertyGrid