import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Subscibe from '../section-components/Subscibe';
import axios from '../../axios'
import APIs from '../../APIs/APIs';
const Footer_v1 = () => {

	const [data, setDate] = useState()


	useEffect(() => {
		if (!data) {

			// setloading(true)
			axios.get(APIs.MainInformation).then((res) => {
				setDate(res.data.data)
				// setloading(false)
			}).catch(error => {
				// setloading(false)
			});
		}

	}, [])

	let IMG_URL = "https://stg.rep.start-tech.ae/"

	return (
		<footer className="footer-area">
			<div className="footer-top">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<a ><img src={`${IMG_URL}${data?.logo}`} style={{ width: "150px" }} alt="img" /></a>
						</div>
						<div className="col-md-8 text-md-right mt-3 mt-md-0">
							<ul className="social-area">
								<li><a href={data?.facebook}><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
								<li><a href={data?.twitter}><i className="fab fa-twitter" aria-hidden="true" /></a></li>
								<li><a href={data?.instagram}><i className="fab fa-instagram" aria-hidden="true" /></a></li>
								<li><a href={data?.youTube}><i className="fab fa-youtube" aria-hidden="true" /></a></li>

							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-middle bg-black">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6">
							<div className="widget widget_about">
								<h4 className="widget-title">Company</h4>
								<div className="details">
									<div className=' mb-3' dangerouslySetInnerHTML={{ __html: data?.description_en }} />
									<div className='d-flex mb-3 '>
										<i className="fas fa-map-marker-alt mt-1 mr-2" />
										<div dangerouslySetInnerHTML={{ __html: data?.addres }} />
									</div>
									<div className=' mb-3' dangerouslySetInnerHTML={{ __html: data?.contactinformation }} />
									{/* <p><i className="fas fa-map-marker-alt" /> {{ __html: data?.addres }}</p> */}
									{/* <p><i className="fas fa-phone-volume" /> +971 45 808 950</p> */}
									{/* <p><i className="fas fa-phone-volume" /> +971 58 567 0236</p> */}
									<p className='mb-3'><i className="fas fa-envelope" /> {data?.email}</p>
									<div className='d-flex '>
										<i className="far fa-clock mt-1 mr-2" />
										<div dangerouslySetInnerHTML={{ __html: data?.workingtimes_en }} />
									</div>
									{/* <p><Link to="/blog"><i className="far fa-clock" />Working Hours: 9:00 am - 6:00 pm ( Monday - Friday ) | 10:00 am - 4:00 pm ( Saturday )</Link></p> */}

								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="widget widget_newsfeed">
								<h4 className="widget-title">QUICK LINKS</h4>
								<ul className="go-top">
									<li><Link to="/properties/sale"><i className="far fa-memo" />BUY</Link></li>
									<li><Link to="/sell"><i className="far fa-" />SELL</Link></li>
									<li><Link to="/properties/rent"><i className="far fa-" />RENT</Link></li>
									<li><Link to="/offplan"><i className="far fa-" />OFF PLAN</Link></li>
									<li><Link to="/team"><i className="far fa-" />TEAM</Link></li>
									<li><Link to="/about"><i className="far fa-" />ABOUT</Link></li>
									<li><Link to="/career"><i className="far fa-" />CAREER</Link></li>
									<li><Link to="/blog"><i className="far fa-" />BLOG</Link></li>
									<li><Link to="/contact"><i className="far fa-" />Contact</Link></li>
									<li><Link to="/privacy-policy"><i className="far fa-" />PRIVACY POLICY</Link></li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4 col-md-9">
							<Subscibe />
							<div className="widget widget-tags pt-2 go-top">
								<img src={`${IMG_URL}${data?.logo}`} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-bottom bg-black">
				<div className="container">
					<div className="row">
						<div className="col-md-7 align-self-center">
							<div dangerouslySetInnerHTML={{ __html: data?.companyfooter_en }} />						</div>
						<div className="col-md-5 text-md-right go-top">
							<ul>
								<li>
									<Link to="/">Home</Link>
								</li>
								<li>
									<Link to="/about">About</Link>
								</li>
								<li>
									<Link to="/blog">Blog</Link>
								</li>
								<li>
									<Link to="/contact">Contact</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>

	)

}


export default Footer_v1