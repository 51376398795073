import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import Service from './section-components/service';
import Product from './section-components/product';
import ProductV2 from './section-components/product-v2';
import Property from './section-components/property';
import Client from './section-components/client';
import LatestNews from './blog-components/latest-news';
import Footer from './global-components/footer';
import Counter from './section-components/counter';
import ContactUs from './section-components/contactUs';
import APIs from '../APIs/APIs';
import axios from '../axios'
import Loader from './section-components/Loader';
const Home_V1 = () => {

    const [data, setDate] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.get(APIs.MainInformation).then((res) => {
            setDate(res.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        });
        window.scrollTo(0, 0)
    }, [])
    return (

        loading ? <Loader loading={loading} color="#fff" height="100" bg="blue" /> :
            <div>
                <Banner data={data} />
                <Service />
                <Product />
                <Property />
                <ProductV2 />
                <Counter cout={data} />
                <Client />
                <LatestNews />
                <ContactUs contact={data} />
                {/* <Footer /> */}
            </div>)
}

export default Home_V1

