import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../axios'
import APIs from '../../APIs/APIs';
const Navbar = () => {

	const [data, setDate] = useState()


	useEffect(() => {
		if (!data) {

			// setloading(true)
			axios.get(APIs.MainInformation).then((res) => {
				setDate(res.data.data)
				// setloading(false)
			}).catch(error => {
				// setloading(false)
			});
		}

	}, [])

	// console.log("API Url", APIs.MainInformation);
	let IMG_URL = "https://stg.rep.start-tech.ae/"




	let publicUrl = process.env.PUBLIC_URL + '/'
	let imgattr = 'logo'
	let anchor = '#'
	return (
		<div className="navbar-area navbar-area-1">
			{/* navbar top start */}
			<div className="navbar-top">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 text-lg-left text-center">
							<ul>
								<li><p><img src={publicUrl + "assets/img/icon/location.png"} alt="img" />{data?.location}</p></li>
								<li><p><img src={publicUrl + "assets/img/icon/phone.png"} alt="img" />{data?.number}</p></li>
								<li><p><img src={publicUrl + "assets/img/icon/envelope.png"} alt="img" />{data?.email}</p></li>
							</ul>
						</div>
						<div className="col-lg-4">
							<ul className="topbar-right text-lg-right text-center">
								<li className="social-area">
									<a href={data?.facebook}><i className="fab fa-facebook-f" aria-hidden="true" /></a>
									<a href={data?.twitter}><i className="fab fa-twitter" aria-hidden="true" /></a>
									<a href={data?.instagram}><i className="fab fa-instagram" aria-hidden="true" /></a>
									<a href={data?.youTube}><i className="fab fa-youtube" aria-hidden="true" /></a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<nav className="navbar navbar-expand-lg">
				<div className="container nav-container">
					<div className="responsive-mobile-menu">
						<button className="menu toggle-btn d-block d-lg-none" data-target="#dkt_main_menu" aria-expanded="false" aria-label="Toggle navigation">
							<span className="icon-left" />
							<span className="icon-right" />
						</button>
					</div>
					<div className="logo">
						<Link to="/"><img src={`${IMG_URL}${data?.logo}`} width="120rem" alt="img" /></Link>
					</div>
					<div className="nav-right-part nav-right-part-mobile">
						<ul>
							<li><a className="search" href="#"><i className="fa fa-search" /></a></li>
							<li><Link className="btn btn-base" to="/add-property"><span className="btn-icon"><i className="fa fa-plus" /></span> <span className="btn-text">SUBMIT PROPERTY</span></Link></li>
						</ul>
					</div>
					<div className="collapse navbar-collapse" id="dkt_main_menu">
						<ul className="navbar-nav menu-open text-center">
							<li className="menu-item-has-children current-menu-item">
								<Link to="/">Services</Link>
								<ul className="sub-menu">
									<li><Link to="/sell">SELL</Link></li>
									<li><Link to="/properties/sale">BUY</Link></li>
									<li><Link to="/properties/rent">RENT</Link></li>
								</ul>
							</li>
							<li className="current-menu-item">
								<Link to="/offPlan">OFF PLAN</Link>
							</li>
							<li className="current-menu-item">
								<a href="#"><Link to="/blog">Blog</Link></a>
							</li>
							<li><Link to="/contact">Contact</Link></li>
							<li className="menu-item-has-children current-menu-item">
								<a href="#">WHO WE ARE</a>
								<ul className="sub-menu">
									<li><Link to="/about">About</Link></li>
									<li><Link to="/team">Team</Link></li>
									<li><Link to="/career">Careers</Link></li>
								</ul>
							</li>
						</ul>
					</div>
					<div className="nav-right-part nav-right-part-desktop">
						<ul>
							<li><Link className="btn btn-base" to="/sell"><span className="btn-icon"><i className="fa fa-plus" /></span> <span className="btn-text">List Your Property</span></Link></li>
						</ul>
					</div>
				</div>
			</nav>
		</div>

	)

}


export default Navbar