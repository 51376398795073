import React, { Component, useState } from 'react';
import APIs from '../../APIs/APIs';
import axios from '../../axios'
import PhoneInput from 'react-phone-input-2'
const ContactUs = ({ contact }) => {

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [mobile_number, setMobile_number] = useState("971")
  const [comment, setComment] = useState("")
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [err_message, setErr_Message] = useState("")

  const clearInput = () => {
    setName("")
    setMobile_number("971")
    setEmail("")
    setComment("")
  }


  const handelSubmit = (e) => {
    setMessage("")
    setErr_Message("")

    e.preventDefault()

    setLoading(true)
    axios.post(APIs.SendMessage, { email, name, mobile_number: `+${mobile_number}`, comment }).then((res) => {
      setMessage(res.data.message)
      clearInput()
      setLoading(false)
    }).catch(error => {
      clearInput()
      // setMessage(error?.response?.data?.message)
      setErr_Message(error?.response?.data?.message)
      setLoading(false)
    });
  }

  const checkInputs = () => {
    if (name.length > 0 && mobile_number.length >= 12) {
      return true
    }
    else {
      return false
    }
  }


  return (
    <div style={{ marginTop: "-4rem" }} className="contact-page-area pd-top-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-7 mb-5 mb-lg-0">
            <div className="contact-details-inner mng-box-shadow">
              <h4>Contact </h4>
              {/* <p>Hello and welcome to RE/MAX Network Home! We are your friendly and local Dubai Real Estate company that is part of an international community of thousands of property firms in more than a hundred countries across every continent on the planet. So, what make our company special? Well, it is not just our globally recognized name which makes us feel proud to be associated with, but also our amazing team of talented people whom we consider as part of our business family.
                Now that we have been introduced, please accept an invitation to our office to meet with us and our experienced Property Consultants.
              RE/MAX Network Home ”bringing the world together”</p> */}
              <div dangerouslySetInnerHTML={{ __html: contact?.description_en }} />
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-single-list">
                    <h5>Address</h5>

                    <div className='d-flex mb-3 '>
                      <i className="fas fa-map-marker-alt mt-1 mr-2" />
                      <div className='contact__' dangerouslySetInnerHTML={{ __html: contact?.addres }} />
                    </div>
                    {/* <ul>
                      <li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />Network Home Real Estate L.L.C Al Habtoor Business Tower Office #1403 Dubai Marina, UAE</li>
                    </ul> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-single-list">
                    <h5>Phone</h5>
                    <div dangerouslySetInnerHTML={{ __html: contact?.contactinformation }} />
                    {/* <ul>
                      <li><img src={publicUrl + "assets/img/icon/6.png"} alt="img" /> +971 45 808 950</li>
                      <li><img src={publicUrl + "assets/img/icon/6.png"} alt="img" /> +971 52 171 2233</li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-5">
            <h2>Keep Up To Date</h2>
            <h6>Contact Form</h6>
            <form>
              <div className="row">
                <div className="col-xl-12 col-lg-6">
                  <label className="single-input-inner style-bg-border">
                    <input type="text" placeholder=" Name" value={name} onChange={(e) => setName(e.target.value)} />
                  </label>
                </div>

                <div className="col-xl-12 col-md-6">
                  <label className="lable_number style-bg-border">
                    <PhoneInput
                      className=" style-bg-border nmber_f"
                      country={'ae'}
                      value={mobile_number}
                      onChange={(e) => setMobile_number(e)}
                    // placeholder='Phone'
                    />
                  </label>
                  <p className=' val_error'> {mobile_number.length > 3 && mobile_number.length < 12 ? "Please enter a valid phone number" : ""}</p>
                </div>


                <div className="col-xl-12 col-md-12 mt-3">
                  <label className="single-input-inner style-bg-border">
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />

                  </label>
                </div>

                <div className="col-12">
                  <label className="single-input-inner style-bg-border">
                    <textarea placeholder="Message" value={comment} onChange={(e) => setComment(e.target.value)} />
                  </label>
                </div>
                <div className="col-12 mb-4">
                  <p className='succus'>{message}</p>
                  <p className='val_error'>{err_message}</p>
                  <button className="btn btn-base" disabled={!checkInputs() || loading} onClick={handelSubmit}>Submit Now</button>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )

}

export default ContactUs