import React from "react";
import { Component } from "react";

const DetailsContainer = ({ our_v, textHeader, text, secoundComponent, secoundComponentHeader }) => {

  return <div className="container">
    <div className="single-about-inner about-line-right bg-white">
      <div className="row no-gutter">
        <div className="col-lg-7 order-lg-1">
          <div className="details">
            <div className="section-title mb-4">
              <h2>{textHeader}</h2>
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
        </div>
        <div className="col-lg-5 order-lg-5">
          <h2 style={{ marginTop: "2%" }}>{secoundComponentHeader}</h2>
          <div style={{ width: "420px", height: "315px" }}>

            {secoundComponent}
          </div>
        </div>
      </div>
    </div>
  </div>
}


export default DetailsContainer;