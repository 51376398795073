import React from 'react'
import CircleLoader from "react-spinners/CircleLoader";
const Loader = ({ color, loading, height, bg }) => {
    return (
        <div className={`loader ${height == "100" ? "loader_vh" : ""} ${bg == "blue" ? "loader_bg" : ""}`}>
            <CircleLoader size={80}
                color={color ? color : '#7C89D1'}
                loading={loading} />
        </div>
    )
}

export default Loader