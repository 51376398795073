import React, { useEffect, useRef, useState } from "react";
import { Component } from "react";
import { Link } from "react-router-dom";

const PropertyOfLatestNew = ({ data }) => {

    let IMG_URL = "https://stg.rep.start-tech.ae/"
    const myDivRef = useRef(null);
    const [text, setText] = useState('');

    const SliceTitle = (title) => {
        // let t = "This is a very long string. It contains more than 150 characters. We need to return only the first 150 characters.";

        const shortString = title.length > 35 ? `${title.slice(0, 35)}...` : title;
        return shortString

    }


    const formatDate = (date) => {
        return `${(new Date(date)).toDateString()} `
    }

    useEffect(() => {
        if (myDivRef.current) {
            // const innerText = "This is a very long string. It contains more than 150 characters. We need to return only the first 150 characters. This is a very long string. It contains more than 150 characters. We need to return only the first 150 characters";
            const innerText = myDivRef.current.innerText;
            setText(`${innerText.length > 125 ? `${innerText.slice(0, 125)}...` : innerText.slice(0, 125)}`);
        }
    }, [myDivRef]);

    return <div>
        <div className="single-product-wrap style-bottom-radius">
            <Link to={`/property-details/${data?.slug}`}>
                <div className="thumb">
                    <img src={IMG_URL + data?.image[0]?.url} alt="img" />
                </div>
            </Link>
            <div className="product-details-inner">
                <ul className="meta-inner">
                    <li><i className="fa fa-user" />{data?.user?.name}</li>
                    <li><i className="fa fa-calendar-alt" />{formatDate(data?.created_at)}</li>
                </ul>
                <h4><Link to={`/property-details/${data?.slug}`}>{SliceTitle(data?.title_en)}</Link></h4>
                <div className="d-none" ref={myDivRef} dangerouslySetInnerHTML={{ __html: data?.description_en }} />
                <div>{text}</div>
                {/* <div>{text.length}</div> */}
                {/* <p>Resort ipsum dolor  consectetur adipisicing elit, sed do eius Lorem ipsum dolo ame conse.</p> */}
                <Link className="btn btn-base read-more" to={`/property-details/${data?.slug}`}> <i className="la la-arrow-right" /></Link>
            </div>
        </div>
    </div >
}

export default PropertyOfLatestNew