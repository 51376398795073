import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyGrid from './section-components/property-grid';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom';
import APIs from '../APIs/APIs';
import axios from '../axios'
import Loader from './section-components/Loader';

const PropertGridPage = () => {
    let params = useParams()
    const propertiesType = params.status// or Rent


    return <div>
        {/* <Navbar /> */}
        <PageHeader headertitle={`Properties listed in For ${propertiesType}`} />
        <PropertyGrid offer={propertiesType} />
        {/* <Footer /> */}
    </div>
}

export default PropertGridPage

