import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom"

const SinglePropertyV2 = ({ item }) => {
    const SliceSize = (size) => {
        // let t = "This is a very long string. It contains more than 150 characters. We need to return only the first 150 characters.";
        const shortString = size.toString().slice(0, 5)

        return shortString

    }


    let publicUrl = process.env.PUBLIC_URL + '/'
    let IMG_URL = "https://stg.rep.start-tech.ae/"

    return <div className="single-product-wrap style-2">
        <Link to={`/property-details/${item?.slug}`}>
            <div className="thumb">
                <img src={IMG_URL + item?.image[0]?.url} alt="img" />
            </div>
            <div className="product-details-inner">
                <h5><Link to="/property-details">{item?.title_en}</Link></h5>
                <ul className="meta-inner">
                    <li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />{item?.geopoints?.location}</li>
                    <li><Link to="/property-details"> {item?.offering_type}</Link></li>
                </ul>
                <div dangerouslySetInnerHTML={{ __html: item?.description_en }} />
                <span className="price">{item?.price} AED</span>
            </div>
            <div className="product-meta-bottom style-2">
                <span>{item?.bedroom}<span>Bedroom</span></span>
                <span className="border-none">{item?.bathroom} <span>Bathroom</span></span>
                <span> {SliceSize(item?.size)}  <span>sqft</span></span>
            </div>
        </Link>
    </div>

}


export default SinglePropertyV2;