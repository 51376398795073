import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import DetailsContainer from './detailsContainer';
import APIs from '../APIs/APIs';
import axios from '../axios'
import Loader from './section-components/Loader';

const Careers = () => {

    const [data, setDate] = useState()
    const [loading, setLoading] = useState(false)
    const fetchAbout = () => {
        setLoading(true)
        axios.get(APIs.Careers).then((res) => {
            setDate(res.data.message)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        });
    }

    useEffect(() => {

        window.scrollTo(0, 0)
        fetchAbout()

    }, [])
    let IMG_URL = "https://stg.rep.start-tech.ae/"
    return (

        loading ? <Loader loading={loading} color="#fff" height="100" bg="blue" /> :

            <div>
                <PageHeader headertitle="Careers" headerImage="http://remaxnetworkhome.com/wp-content/uploads/2022/05/Colleyville-real-estate.jpeg" />
                <DetailsContainer text={data?.careersexpression_en} secoundComponent={<img style={{ marginTop: "10%", width: "100%" }} src={IMG_URL + data?.careersvideo} />} />
            </div>)
}

export default Careers

