import React from "react";
import { Component } from "react";


const SelectionButton = ({ filter_data, filterName, setValue, f_option, value, keyOrV }) => {
    // console.log("filter_data", filter_data);
    return <div className="col-md-3 mt-2 mt-md-0 align-self-center">
        <div className="single-select-inner ">
            <select className="nice-select" value={value} onChange={(e) => setValue(e.target.value)}>
                <option value={f_option}>{filterName}</option>
                {

                    Object.entries(filter_data || {}).map(([key, value]) => {

                        return (
                            <option key={key} value={keyOrV == "key" ? key : value}>{value}</option>
                        )
                    })
                }

            </select>
        </div>
    </div>

}

export default SelectionButton;