import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Page_header extends Component {

	render() {
		let headerImage = this.props.headerImage || "http://remaxnetworkhome.com/wp-content/uploads/2021/11/shutterstock_435578038.png"
		let HeaderTitle = this.props.headertitle;
		let publicUrl = process.env.PUBLIC_URL + '/'
		let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle
		let extraText = this.props.extraText
		return (

			<div className="breadcrumb-area bg-overlay-2" style={{ backgroundImage: `url(${headerImage})` }}>
				<div className="container">
					<div className="breadcrumb-inner">
						<div className="section-title text-center">
							<h2 className="page-title">{HeaderTitle}</h2>
							<div dangerouslySetInnerHTML={{ __html: extraText }} />
							{/* <p style={{color: "#FFF", fontSize:"25px"}}>{extraText}</p> */}
						</div>
					</div>
				</div>
			</div>



		)
	}
}


export default Page_header