import React, { useEffect, useState } from "react";
import Navbar from "./global-components/navbar";
import Member from "./section-components/member";
import Footer from "./global-components/footer";
import PageHeader from "./global-components/page-header";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import APIs from '../APIs/APIs';
import axios from '../axios'
import Loader from './section-components/Loader';

const TeamMember = () => {

    let { id } = useParams()

    const [data, setDate] = useState()
    const [loading, setLoading] = useState(false)
    const fetchTeams = () => {
        setLoading(true)
        axios.get(`${APIs.Team}/${id}`).then((res) => {
            setDate(res.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchTeams()

    }, [])
    return (
        loading ? <Loader loading={loading} color="#fff" height="100" bg="blue" /> :
            <div>
                <PageHeader headertitle="Meet Out Team" titleImage="http://remaxnetworkhome.com/wp-content/uploads/2022/06/Depositphotos_25685857_xl-2015-copy-scaled-e1633104480116.jpg" />
                <Member data={data} />
            </div>
    )
}

export default TeamMember