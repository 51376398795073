import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';



export const MapContainer = (props) => {

    const mapStyles = {
        width: props.width,
        height: props.height,


    };

    const defaultCenter = {
        lat: props.location?.lat,
        lng: props.location?.lng

    };
    return (
        <Map
            google={props.google}
            zoom={props.zoom ? props.zoom : 15}
            style={mapStyles}
            initialCenter={defaultCenter}

        >
            <Marker position={defaultCenter} />
        </Map>
    );

}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDToUQHD7_Ili1OcLCWuG4jLDCg6AVdm_Y'
})(MapContainer);