import React, { Component, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV1 from './components/home-v1';
import ProptertyDetails from './components/property-details';
import ProptertyGrid from './components/property-grid';
import About from './components/about';
import Team from './components/team';
import Contact from './components/contact';
import Blog from './components/blog';
import OffPlan from './components/offPlan';
import Careers from './components/careers'
import Sell from './components/sell';
import TeamMember from './components/member';
import FeaturedDevelopers from './components/featureddevelopers';
import PrivacyPolicy from './components/privacyPolicy';
import BlogDetailes from './components/blogDetailes';
import PopularCommunities from './components/popularCommunities';
import Navbar from './components/global-components/navbar';
import Footer_v1 from './components/global-components/footer';
import axios from './axios'
import APIs
    from './APIs/APIs';
import Loader from './components/section-components/Loader';

import 'react-phone-input-2/lib/style.css'

const Root = () => {




    return (
        <HashRouter basename="/">
            {

                <>
                    <Navbar />
                    <Switch>
                        <Route exact path="/" component={HomeV1} />
                        <Route path="/properties/:status" component={ProptertyGrid} />
                        <Route path="/offPlan" component={OffPlan} />
                        <Route path="/about" component={About} />
                        <Route path="/team" component={Team} />
                        <Route path="/career" component={Careers} />
                        <Route path="/property-details/:id" component={ProptertyDetails} />
                        <Route path="/team-member/:id" component={TeamMember} />
                        <Route path="/featureddevelopers/:id" component={FeaturedDevelopers} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/blog" component={Blog} />
                        <Route path="/sell" component={Sell} />
                        <Route path="/blog-details" component={BlogDetailes} />
                        <Route path="/popular-communitie-details" component={PopularCommunities} />
                    </Switch>
                    <Footer_v1 />
                </>
            }
        </HashRouter>
    )
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('mingrand'));
