import React, { useEffect, useState } from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import PrivacyPolicy1 from "./section-components/privacyPolicy";
import APIs from '../APIs/APIs';
import axios from '../axios'
import Loader from './section-components/Loader';

const PrivacyPolicy = () => {
    const [data, setDate] = useState()
    const [loading, setLoading] = useState(false)
    const fetchAbout = () => {
        setLoading(true)
        axios.get(APIs.privacyPolicy).then((res) => {
            setDate(res.data.message)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchAbout()

    }, [])
    return (
        loading ? <Loader loading={loading} color="#fff" height="100" bg="blue" /> :
            <div>
                <PageHeader headertitle="Privacy Policy" />
                <PrivacyPolicy1 data={data} />
            </div>)
}

export default PrivacyPolicy