import React from "react";
import { Component } from "react";

const FeaturedDevelopers = ({ data }) => {

    let IMG_URL = "https://stg.rep.start-tech.ae/"
    return <div className="container">
        <div className="row">
            <div className="col-md-12 col-lg-6 col-sm-12">
                <h1>{data?.name}</h1>
                <div dangerouslySetInnerHTML={{ __html: data?.description }} />

                {/* <p>OMNIYAT is one of the most visionary privately held real-estate development and service groups in the Gulf Region. Since 2005, OMNIYAT has created living canvases of residential, commercial, hospitality and retail spaces for a one-of-a-kind premium experience. We approach the design, development and management of each OMNIYAT property as if it were a unique work of art. We nurture and maintain close relationships with the world’s leading architects, engineers, interior designers, and artists. Each project is unique, designed to create a superior return on investment and to give every homeowner the OMNIYAT guarantee of living in a bespoke space that reflects their personality, achievements, and ambitions.</p> */}
            </div>
            <div style={{ marginTop: "2rem" }} className="col-md-12 col-lg-6 col-sm-12">
                <img src={IMG_URL + data?.image}></img>
            </div>
        </div>
    </div>
}


export default FeaturedDevelopers