import React, { useState } from "react";
import { Component } from "react";
import APIs from '../../APIs/APIs';
import axios from '../../axios'
import PhoneInput from 'react-phone-input-2'


const Member = ({ data }) => {

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [mobile_number, setMobile_number] = useState("971")
    const [comment, setComment] = useState("")
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [err_message, setErr_Message] = useState("")

    const [nameErr, setNameErr] = useState("")
    const [phoneErr, setPhoneErr] = useState("")

    const clearInput = () => {
        setName("")
        setMobile_number("971")
        setEmail("")
        setComment("")
    }

    const checkInput = () => {

        if (name.length > 0 && mobile_number.length > 11) {
            return true
        }
        else {
            return false
        }

    }


    const handelSubmit = (e) => {
        setErr_Message('')
        setMessage('')
        e.preventDefault()

        if (checkInput()) {


            setLoading(true)
            axios.post(`${APIs.Team}/${data?.id}/send-message`, { email, name, mobile_number: `+${mobile_number}`, comment }).then((res) => {
                setMessage(res.data.message)
                clearInput()
                setLoading(false)
            }).catch(error => {
                setErr_Message(error?.response?.data?.message)
                setLoading(false)
            });
        }
    }


    let IMG_URL = "https://stg.rep.start-tech.ae/"
    const [isContact, setIsContact] = useState(false)
    let publicUrl = process.env.PUBLIC_URL
    let checkImage = data?.image ? IMG_URL + data.image : "https://i0.wp.com/remaxnetworkhome.com/wp-content/uploads/2022/06/4.jpg?fit=1080%2C1080&ssl=1"


    return <div className="container">
        <div className="row">
            <div className="col-md-6 col-sm-12 single-agent-wrap">
                <div className="thumb">
                    <img src={checkImage} alt="img" />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <h3 style={{ marginTop: "5rem" }}>{data?.name}</h3>
                <h5>{data?.role}</h5>
                <p>Email: {data?.email}</p>
                <p>Mobile No: {data?.phone}</p>
                <p>BRN#: {data?.brn}</p>
                <button onClick={() => setIsContact(!isContact)} className="btn btn-base radius-btn mb-2">Contact Me</button>
                {isContact ? (
                    <div className="row">
                        <div style={{ marginTop: "1rem" }} className="col-12">
                            <label className="single-input-inner style-bg">
                                <input type="text" placeholder="Name ..." value={name} onChange={(e) => setName(e.target.value)} />
                                {nameErr && <p className='about_me_check'>{nameErr}</p>}
                            </label>
                        </div>
                        <div className="col-12">
                            <label className="lable_number style-bg-border number_blue_flag">
                                <PhoneInput
                                    className=" style-bg-border nmber_f number_blue style-bg"
                                    country={'ae'}
                                    value={mobile_number}
                                    onChange={(e) => setMobile_number(e)}
                                // placeholder='Phone'
                                />
                            </label>
                            {mobile_number.length > 3 && mobile_number.length <= 11 ? <p className='about_me_check val_error'>Please enter valid mobile number.</p> : ""}

                            {/* <label className="single-input-inner style-bg">
                                <input type="number" placeholder="Phone Number ..." value={mobile_number} onChange={(e) => setMobile_number(e.target.value)} />
                                {mobile_number.length > 1 && mobile_number.length <= 9 ? <p className='about_me_check val_error'>Please enter valid mobile number.</p> : ""}
                            </label> */}
                        </div>
                        <div className="col-12 mt-3">
                            <label className="single-input-inner style-bg">
                                <input type="text" placeholder="Your email here...." value={email} onChange={(e) => setEmail(e.target.value)} />
                            </label>
                        </div>
                        <div className="col-12">
                            <label className="single-input-inner style-bg">
                                <textarea type="text" placeholder="Your message here...." value={comment} onChange={(e) => setComment(e.target.value)} cols={3} />
                            </label>
                        </div>
                        <div className="col-12">
                            {message && <><p className='mb-2 succus'>{message}</p><br /></>}
                            {err_message && <><p className=' mb-2 val_error'>{err_message}</p> <br /></>}
                            <button style={{ marginTop: "" }} className="btn btn-base radius-btn  mb-2" disabled={!checkInput() || loading} onClick={handelSubmit}>Submit</button>
                        </div>
                    </div>
                ) : (
                    ""
                )}

            </div>

        </div>
    </div>

}

export default Member