import React, { useEffect, useState } from "react";
import SingleBlogCard from "./singleBlogCard";
import APIs from "../APIs/APIs";
import axios from '../axios'
import Loader from "./section-components/Loader";
const Blogs = () => {


    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    let IMG_URL = "https://stg.rep.start-tech.ae/"

    const fetchBlogs = () => {
        setLoading(true)
        axios.get(APIs.Blogs).then((res) => {
            setData(res?.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchBlogs()
    }, [])

    return (

        loading ? <Loader loading={loading} color="#fff" height="100" bg="blue" /> : <div className="blog-page-area pt-5 go-top">
            <div className="container">
                <div className="row">
                    {
                        data?.map((item, idx) => {
                            return (
                                <div className="col-lg-4 col-md-6">
                                    <SingleBlogCard blogName={item?.title}
                                        explanations={item?.body}
                                        blogStatus={item?.posted_by}
                                        blogDate={item?.date}
                                        image={IMG_URL + item?.image[0]?.url}
                                        id={item?.id}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>)
}

export default Blogs