import React, { useState } from "react";
import { Component } from "react";
import APIs from "../../APIs/APIs";
import axios from '../../axios'
import PhoneInput from 'react-phone-input-2'

const SellForm = ({ data }) => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("971")
    const [location, setlocation] = useState("")
    const [propertyType, setPropertyType] = useState("")
    const [propertyFor, setPropertyFor] = useState("1")
    const [message, setMessage] = useState("")
    const [err_message, setErr_Message] = useState("")
    const [loading, setLoading] = useState(false)

    const [nameErr, setNameErr] = useState("")
    const [phoneErr, setPhoneErr] = useState("")
    const [propertyErr, setPropertyErr] = useState("")
    const [locationErr, setLocationErr] = useState("")

    const clearInput = () => {
        setName('')
        setEmail('')
        setPhone('971')
    }

    const checkInput = () => {
        setNameErr('')
        setPhoneErr('')
        setMessage("")
        setErr_Message("")
        setPropertyErr("")
        setLocationErr("")
        if (name.length == 0) {
            setNameErr("The name field is required.")

        }
        if (phone.length <= 11) {
            setPhoneErr("Please enter valid mobile number.")

        }
        if (propertyType.length == 0) {
            setPropertyErr("Please fill out this property type field.")

        }
        if (location.length == 0 || location == "Location") {
            setLocationErr("Please fill out this location field.")

        }
        if (name.length > 0 && phone.length > 9 && propertyType.length > 0 && (location.length > 0 && location !== "Location")) {
            return true
        }
        else {
            return false
        }

    }

    const sendEnquiry = (e) => {
        e.preventDefault()
        if (checkInput()) {

            setLoading(true)
            axios.post(APIs.listProperty, { email, name, phone: `+${phone}`, location, propertyType, propertyFor }).then((res) => {
                setMessage(res.data.message)
                clearInput()
                // setEmail("")

                setLoading(false)
            }).catch(error => {
                // setEmail("")
                // setMessage(error?.response?.data?.message)
                setErr_Message(error?.response?.data?.message)
                setLoading(false)
            });
        }
    }
    return <div className="container">
        <div className="col-6 btn btn-base hover-none">If you list now property valuation is on us</div>
        <div className="row">
            <div className="col-6 list-form-grid">
                <form>
                    <div className="row">
                        <div className="col-md-12">
                            <label className="single-input-inner style-bg-border">
                                <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                                {nameErr && <p className='about_me_check val_error'>{nameErr}</p>}
                            </label>
                        </div>
                        <div className="col-md-12">
                            <label className="single-input-inner style-bg-border">
                                <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </label>
                        </div>
                        <div className="col-md-12">
                            <label className="lable_number style-bg-border">
                                <PhoneInput
                                    className=" style-bg-border nmber_f"
                                    country={'ae'}
                                    value={phone}
                                    onChange={(e) => setPhone(e)}
                                    placeholder='Phone'
                                />
                            </label>
                            {phoneErr && <p className='about_me_check val_error'>{phoneErr}</p>}

                        </div>
                        <div className="row">
                            <div className="col-md-12 mt-3">
                                <select className="single-select-inner nice-select select_input m-b-0" value={propertyFor} onChange={(e) => setPropertyFor(e.target.value)}>
                                    <option value="1">Sell </option>
                                    <option value="2"> Rent</option>
                                </select>
                            </div>
                            <div className="col-md-12 mt-4 ">
                                <select className="single-input-inner nice-select select_input m-b-0" value={location} onChange={(e) => setlocation(e.target.value)}>
                                    <option >Location</option>
                                    {
                                        data?.communities?.map(item => {
                                            return (

                                                <option key={item?.id} value={item?.id}>{item?.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                {locationErr && <p className='ml-3 ma_t about_me_check val_error'>{locationErr}</p>}
                            </div>
                            <div className="col-md-12 mt-4">
                                <select className="single-select-inner nice-select select_input m-b-0" value={propertyType} onChange={(e) => setPropertyType(e.target.value)}>
                                    <option >Property Type</option>
                                    {

                                        Object.entries(data?.property_typs || {}).map(([key, value]) => {

                                            return (
                                                <option key={key} value={value}>{value}</option>
                                            )
                                        })
                                    }
                                </select>
                                {propertyErr && <p className='about_me_check ml-3 mt-0 val_error'>{propertyErr}</p>}
                            </div>
                        </div>

                        <div className="mt-4">
                            <p className="ml-4 succus">{message}</p>
                            <p className="ml-4 val_error">{err_message}</p>
                            <div className="">
                                <button className="btn btn-base ml-3" onClick={sendEnquiry}>Send Enquiry</button>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-md-6">
                <div dangerouslySetInnerHTML={{ __html: data?.sellpageexpression_en }} />
                {/* <h6 style={{color:"Black"}}>One of the most important decisions you will make is selecting the right real estate agent to sell your home. Your home is a very personal, important, and valuable asset, and selling it can be an emotional and complicated process, especially in today’s difficult market. Numerous questions and concerns will need to be addressed, and the advice and consultation you receive can make a significant difference.
                    We provide the best market price for your home, in the shortest time, with the least hassles.</h6>
                <h3>Contact us today for a free valuation.</h3> */}
            </div>
        </div>
    </div>
}


export default SellForm;