import React from 'react';
import Agent from './section-components/agent';
import Navbar from './global-components/navbar';
import Footer from './global-components/footer';
import PageHeader from './global-components/page-header'

const OffPlan = () => {
    return <div>
        {/* <Navbar /> */}
        <PageHeader headertitle="Off Plan" headerImage="http://remaxnetworkhome.com/wp-content/uploads/2021/11/shutterstock_435578038.png" />
        <Agent />
        {/* <Footer /> */}
    </div>
}

export default OffPlan