import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom"
class OffPlanCard extends Component{
    
    render(){
        let publicUrl = process.env.PUBLIC_URL+"/"
    return <div className="single-agent-wrap">
                    <Link to="/properties/Off Plan">
                    <div className="thumb">
                        <img src={publicUrl+"assets/img/agent/1.png"} alt="img" />
                    </div> 
                    <div className="details">
                        {/* ================Card Info Section==================== */}
                        <div className='row' >
                            <p className='col-md-4'><Link to="/"><h4>AZIZA</h4></Link></p>
                        </div>
                        <div className='row' >
                            <p className='col-6' style={{bottom:"-2rem"}}><Link to="/"><h4>Learn More</h4></Link></p> 
                            <p className='col-6' style={{bottom:"-2rem"}}><Link to="/"><h4>View The Plan</h4></Link></p> 
                        </div>
                        {/* ================End Of Card Info Section==================== */}
                    </div>   
                    </Link>     
        </div>
    }
}

export default OffPlanCard;