import React from "react";
import { Component } from "react";

const PrivacyPolicy = ({ data }) => {
    return <div className="container">
        <div dangerouslySetInnerHTML={{ __html: data?.privacypolicy_en }} />

    </div>


}


export default PrivacyPolicy