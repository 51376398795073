import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactForm from './section-components/contact-form';
import Footer from './global-components/footer';
import APIs from '../APIs/APIs';
import axios from '../axios'
import Loader from './section-components/Loader';

const ContactPage = () => {

    const [data, setDate] = useState()
    const [loading, setLoading] = useState(false)
    const fetchAbout = () => {
        setLoading(true)
        axios.get(APIs.contactUs).then((res) => {
            setDate(res.data.message)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        });
    }

    useEffect(() => {

        window.scrollTo(0, 0)
        fetchAbout()

    }, [])

    return (

        loading ? <Loader loading={loading} color="#fff" height="100" bg="blue" /> :

            <div>
                <PageHeader headertitle="Contact" />
                <ContactForm data={data} />
            </div>)
}

export default ContactPage

