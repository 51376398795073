import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import APIs from '../../APIs/APIs';
import axios from '../../axios'
import Loader from './Loader';
import AboutMe from './aboutMe';
import GMap from './MapContainer';
import MapContainer from './MapContainer';

const PropertyDetails = () => {
	let { id } = useParams();
	let IMG_URL = "https://stg.rep.start-tech.ae/"

	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [comment, setComment] = useState("")
	const [commentLoading, setCommentLoading] = useState(false)
	const [result, setResult] = useState("")
	const [err_result, setErrResult] = useState("")

	const [data, setDate] = useState()
	const [similarProperties, setSimilarProperties] = useState()
	const [loading, setLoading] = useState(false)
	const [latestUpdates, setLatestUpdates] = useState()
	const [latestUpdatesLoading, setLatestUpdatesLoading] = useState(false)

	const [similarloading, setSimilarLoading] = useState(false)
	const fetchPropertyDetails = () => {
		setErrResult("")
		setResult("")
		setLoading(true)
		axios.get(`${APIs.propertyDetails}/${id}`).then((res) => {
			setDate(res.data.data)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
		});
	}
	const fetchsimilarProperties = () => {
		setSimilarLoading(true)
		axios.get(`${APIs.propertyDetails}/${id}/similar-properties`).then((res) => {
			setSimilarProperties(res.data.data)
			setSimilarLoading(false)
		}).catch(error => {
			setSimilarLoading(false)
		});
	}
	const fetchLatestUpdates = () => {
		setSimilarLoading(true)
		axios.get(`${APIs.LatestUpdates}`).then((res) => {
			setLatestUpdates(res.data.data)
			setLatestUpdatesLoading(false)
		}).catch(error => {
			setLatestUpdatesLoading(false)
		});
	}

	const addCommint = (e) => {
		e.preventDefault();
		setCommentLoading(true)
		axios.post(`${APIs.propertyDetails}/${id}/add-comment`, {
			email, name, comment
		}).then((res) => {
			setResult(res.data.message)
			setCommentLoading(false)
			setComment("")
			setName("")
			setEmail("")
		}).catch(error => {
			setCommentLoading(false)
		});
	}
	useEffect(() => {

		window.scrollTo(0, 0)
		fetchPropertyDetails()
		fetchsimilarProperties()
		fetchLatestUpdates()

	}, [id])



	let publicUrl = process.env.PUBLIC_URL + '/'

	return (
		loading ? <Loader loading={loading} color="#fff" height="100" bg="blue" /> :
			<div className="property-page-area pd-top-120 pd-bottom-90 ">
				<div className="container">
					<div className="property-details-top pd-bottom-70">
						<div className="property-details-top-inner">
							<div className="row">
								<div className="col-lg-7">
									<h3>{data?.title_en}</h3>
									<p><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" /> {data?.geopoints?.location} </p>
									<ul>
										<li>{data?.bedroom} Bedrooms</li>
										<li>{data?.bathroom} Bathrooms</li>
										<li>{data?.size} sqft</li>
									</ul>
								</div>
								<div className="col-lg-5 text-lg-right">
									<h3>AED {data?.price} </h3>
									<div className="btn-wrap">
										<a style={{ height: "1.5rem", width: "6rem", fontSize: "1rem" }} className="btn btn-base btn-sm" href="#">{data?.offering_type}</a>
										<a style={{ height: "1.5rem", width: "6rem", fontSize: "1rem" }} className="btn btn-blue btn-sm" href="#">{data?.property_type}</a>
										{/* <a className="btn btn-blue btn-sm" href="#"></a> */}
									</div>
									<ul>
										<li><img src={publicUrl + "assets/img/icon/1.png"} alt="img" />{(new Date(data?.created_at)).toDateString()} </li>
										<li><img src={publicUrl + "assets/img/icon/2.png"} alt="img" />{data?.views_count}</li>
										<li><img src={publicUrl + "assets/img/icon/3.png"} alt="img" />{data?.comments_count}    </li>
									</ul>
								</div>
							</div>
						</div>
						<div className="product-thumbnail-wrapper">
							<div className="single-thumbnail-slider">
								{
									data?.image?.map((item, idx) => {
										return (

											<div className="slider-item" key={idx}>
												<img src={IMG_URL + item?.url} alt="img" style={{ width: "100%" }} />
											</div>
										)
									})
								}
								{/* <div className="slider-item">
								<img src={publicUrl + "assets/img/project-single/1.png"} alt="img" />
							</div> */}

							</div>
							{/* <div className="product-thumbnail-carousel">
							<div className="single-thumbnail-item">
								<img src={publicUrl + "assets/img/project-single/1.png"} alt="img" />
							</div>

						</div> */}
						</div>
					</div>
					<div className="row go-top">
						<div className="col-lg-8">
							<div className="single-property-details-inner">
								<h4>Description</h4>
								<div dangerouslySetInnerHTML={{ __html: data?.description_en }} />
								<div className="single-property-grid">
									<h4>Poperty Details</h4>
									{/* <div className="row">
										<div className="col-md-4">
											<ul style={{ listStyleType: "square" }}>
												<li>4,178 sqft Plot</li>
												<li>3,167 sqft Built Up Area</li>
												<li>Luxury Villa</li>
												<li>Rented</li>
											</ul>
										</div>
										<div className="col-md-4">
											<ul>
												<li>Bedrooms: 3</li>
												<li>Livingroom: 2</li>
												<li>Year Built: 2020</li>
												<li>Area: 1258</li>
											</ul>
										</div>
										<div className="col-md-4">
											<ul>
												<li>Bedrooms: 3</li>
												<li>All Rooms: 12</li>
												<li>Kitchen: 2</li>
												<li>Type: Privet House</li>
											</ul>
										</div>
									</div> */}
								</div>
								<div className="single-property-grid">
									<h4>Amenities</h4>
									<div className="row">
										{
											data?.private_amenities?.map((item, idx) => {
												return (

													<div className="col-md-4" key={idx}>
														<p><i className="fa fa-check mr-2 text-success" />{item}</p>
													</div>
												)
											})
										}


									</div>
								</div>
								{/* <div className="single-property-grid">
								<h4>Additional Details</h4>
								<div className="row">
									<div className="col-md-4">
										<ul>
											<li>Remodale Year: 3</li>
											<li>Amenites: Half of Fame</li>
											<li>Equepment: Grill-gass</li>
										</ul>
									</div>
									<div className="col-md-4">
										<ul>
											<li>Diposit: 7065$</li>
											<li>Pool Size: 1620</li>
											<li>Additional Room: 2</li>
										</ul>
									</div>
									<div className="col-md-4">
										<ul>
											<li>Ground: 2</li>
											<li>Additional Room: 2</li>
											<li>Floor: 1203</li>
										</ul>
									</div>
								</div>
							</div> */}
								<div className="single-property-grid">
									<h4>Address</h4>
									<div className="row">
										<div dangerouslySetInnerHTML={{ __html: data?.description_en }} />
									</div>
									{/* <button className="btn btn-base radius-btn">See in Google maps</button> */}
								</div>
								<div className="single-property-grid">
									<h4>Estate Location</h4>
									<div className='map h400'>

										<MapContainer location={data?.geopoints} height="400px" width="100%" />
									</div>
									{/* <div className="property-map">
										<iframe src={`https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d465540.3416004624!2d${data?.geopoints?.lng}!3d${data?.geopoints?.lat}!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbg!4v1690030982071!5m2!1sen!2sbg`} />

									</div> */}
									{/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d198059.49240377638!2d-84.68048827338674!3d39.13652252762691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1615660592820!5m2!1sen!2sbd" /> */}
								</div>
								<div className="single-property-grid">
									<h4>Similar Listings</h4>
									{
										similarloading ? <Loader loading={similarloading} color="#0d1741" /> :

											similarProperties?.map((item, idx) => {
												return (
													<div className="media single-review-inner" key={idx}>
														<div className="media-left">
															<div className="thumb">
																<img src={IMG_URL + item?.image[0]?.url} alt="img" width={100} />
															</div>
														</div>
														<div className="media-body align-self-center">
															<div className="row">
																<div className="col-md-8">

																	<h5><Link to={`/property-details/${item?.slug}`}>{item?.title_en}</Link></h5>
																	<div dangerouslySetInnerHTML={{ __html: item?.description_en }} />
																</div>
																<div className="col-md-4 text-md-right">
																	<p className="ratting-title"><span>32</span> Reviews</p>
																	<div className="ratting-inner">
																		<i className="fa fa-star" />
																		<i className="fa fa-star" />
																		<i className="fa fa-star" />
																		<i className="fa fa-star" />
																		<i className="fa fa-star" />
																	</div>
																</div>
															</div>
														</div>
													</div>
												)
											})
									}


								</div>
								<form className="single-property-comment-form">
									<div className="single-property-grid bg-black">
										<div className="single-property-form-title">
											<div className="row">
												<div className="col-md-8">
													<h4>Post Your Comment</h4>
												</div>
												<div className="col-md-4 text-md-right">
													<div className="ratting-inner">
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star" />
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-6">
												<label className="single-input-inner style-bg">
													<span className="label">Enter Your Name*</span>
													<input type="text" placeholder="Your name here...." onChange={(e) => setName(e.target.value)} value={name} />
												</label>
											</div>
											<div className="col-lg-6">
												<label className="single-input-inner style-bg">
													<span className="label">Enter Your E-mail*</span>
													<input type="text" placeholder="Your email here...." onChange={(e) => setEmail(e.target.value)} value={email} />
													<p className='val_error'>{email.length > 1 && !/\S+@\S+\.\S+/.test(email) ? "Please enter a valid email." : ""}</p>
												</label>
											</div>
											<div className="col-12">
												<label className="single-input-inner style-bg">
													<span className="label">Enter Your Messege*</span>
													<textarea placeholder="Enter your messege here...." onChange={(e) => setComment(e.target.value)} value={comment} />
												</label>
											</div>
											<p className='succus'>{result}</p>
											<p className='val_error'>{err_result}</p>
											<div className="col-12 mb-4">
												<button disabled={!/\S+@\S+\.\S+/.test(email) || comment.length < 1 || name.length < 1 || commentLoading} className="btn btn-base radius-btn" onClick={addCommint}>Submit Now</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-4">
							<aside className="sidebar-area">
								<AboutMe data={data} id={id} />
								<div className="widget widget-news">
									<h5 className="widget-title">Recent Properties</h5>
									{
										latestUpdatesLoading ? <h2>loading</h2> :
											latestUpdates?.map((item, idx) => {
												return (
													<div className="single-news-wrap media" key={idx}>
														<div className="thumb">
															<img src={IMG_URL + item?.image[0]?.url} alt="img" width={70} height={70} />
														</div>
														<div className="media-body align-self-center">
															<h6><Link to={`/property-details/${item?.slug}`}>{item?.title_en}</Link></h6>
															<p className="date"><i className="far fa-calendar-alt" />{(new Date(item?.created_at)).toDateString()}</p>
														</div>
													</div>
												)
											})
									}


								</div>
							</aside>
						</div>
					</div>
				</div>
			</div>)


}

export default PropertyDetails