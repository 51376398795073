import React from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";

const PopularCommunities = () => {
    return <div>
        <Navbar />
        <Footer />
    </div>
}

export default PopularCommunities