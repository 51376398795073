import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import Blogs from './blogs';

const BlogPage = () => {
    return <div>
        {/* <Navbar /> */}
        <PageHeader headertitle="Dubai Real Estate Blog" headerImage="https://remaxnetworkhome.com/wp-content/uploads/2022/09/dubai-blog.jpg" />
        <Blogs />
        {/* <Footer /> */}
    </div>
}

export default BlogPage

