import React, { useEffect, useRef, useState } from "react"
import { Component } from "react"
import { Link } from "react-router-dom"

const SingleProperty = ({ data }) => {
  let IMG_URL = "https://stg.rep.start-tech.ae/"

  const formatDate = (date) => {
    return `${(new Date(date)).toDateString()}`


  }

  const myDivRef = useRef(null);
  const [text, setText] = useState('');

  const SliceTitle = (title) => {
    // let t = "This is a very long string. It contains more than 150 characters. We need to return only the first 150 characters.";

    const shortString = title.length > 35 ? `${title.slice(0, 35)}...` : title;
    return shortString

  }

  useEffect(() => {
    if (myDivRef.current) {
      // const innerText = "This is a very long string. It contains more than 150 characters. We need to return only the first 150 characters. This is a very long string. It contains more than 150 characters. We need to return only the first 150 characters";
      const innerText = myDivRef.current.innerText;
      setText(`${innerText.length > 125 ? `${innerText.slice(0, 125)}...` : innerText.slice(0, 125)}`);
    }
  }, [myDivRef]);
  let publicUrl = process.env.PUBLIC_URL + '/'

  return <div className="single-product-wrap style-bottom">
    <Link to={`/property-details/${data?.slug}`}>
      <div className="thumb">
        <img src={IMG_URL + data?.image[0]?.url} alt="img" />
        <div className="product-wrap-details">
          <div className="media">
            <div className="media-body">
              <p><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" className="location_img" width="20" height="20" />{data?.geopoints?.location} </p>
            </div>
          </div>
        </div>
      </div>
      <div className="product-details-inner">
        <h4><Link to={`/property-details/${data?.slug}`}> {SliceTitle(data?.title_en)}</Link></h4>
        <ul className="meta-inner">
          <li><Link to="/property">{data?.offering_type}</Link></li>
        </ul>
        {/* <div dangerouslySetInnerHTML={{ __html: data?.description_en }} /> */}
        <div className="d-none" ref={myDivRef} dangerouslySetInnerHTML={{ __html: data?.description_en }} />
        <div className="format_des">{text}</div>
      </div>
      <div className="product-meta-bottom">
        <span className="price">$ {data?.price}</span>
        <span>{data?.offering_type}</span>
        <span>{formatDate(data?.created_at)}</span>
      </div>
    </ Link>
  </div>


}

export default SingleProperty