import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";

const TeamMemCard = ({ memberImage, memberName, role, id }) => {
    let IMG_URL = "https://stg.rep.start-tech.ae/"
    let image = IMG_URL + memberImage || "https://i0.wp.com/remaxnetworkhome.com/wp-content/uploads/2022/06/4.jpg?fit=1080%2C1080&ssl=1"
    let link = "team-member/" + id
    return <Link to={link}>
        <div className="single-agent-wrap style-2 text-center">
            <div className="thumb">
                <img src={image} alt="img" />
            </div>
            <div className="details">
                <h4>{memberName}</h4>
                <h6>{role}</h6>
            </div>
        </div>
    </Link>
}

export default TeamMemCard