import React, { useEffect, useState } from "react";
import { Component } from "react";
import Navbar from "./global-components/navbar";
import FeaturedDev from './section-components/featuredDev';
import Footer from "./global-components/footer";
import Featureddevelopers from "./section-components/featureddevelopers";
import APIs from '../APIs/APIs';
import axios from '../axios'
import Loader from './section-components/Loader';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const FeaturedDevelopers = () => {
    let { id } = useParams()

    const [data, setDate] = useState()
    const [loading, setLoading] = useState(false)
    const fetchAbout = () => {
        setLoading(true)
        axios.get(`${APIs.featuredDeveloper}/${id}`).then((res) => {
            setDate(res.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchAbout()

    }, [])

    return (

        loading ? <Loader loading={loading} color="#fff" height="100" bg="blue" /> :

            <div>
                <FeaturedDev image={data} />
                <Featureddevelopers data={data} />
                {/* <Footer /> */}
            </div>)
}


export default FeaturedDevelopers   