import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropertyOfLatestNew from '../section-components/propertyOfLatestNews';
import APIs from '../../APIs/APIs';
import axios from '../../axios'
import Loader from '../section-components/Loader';
const LatestNews = () => {

	const [data, setData] = useState()
	const [loading, setLoading] = useState(false)


	const fetchLatestUpdates = () => {
		setLoading(true)
		axios.get(APIs.LatestUpdates).then((res) => {
			setData(res.data.data)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
		});
	}

	useEffect(() => {

		fetchLatestUpdates()
	}, [])



	let publicUrl = process.env.PUBLIC_URL + '/'
	let imagealt = 'image'
	return (
		<div className="blog-area pd-top-118 pd-bottom-90 go-top">
			<div className="container">
				<div className="section-title text-center">
					<h2>Latest Updates</h2>
				</div>
				<div className="row justify-content-center">
					{
						loading ? <Loader loading={loading} color="#0d1741" /> :
							data?.map((item, idx) => {
								return (
									<div className="col-lg-4 col-md-6" key={idx}>
										<PropertyOfLatestNew data={item} />
									</div>
								)
							})
					}

				</div>
			</div>
		</div>

	)
}


export default LatestNews;
