import React from "react"
import { Component } from "react"
import { Link } from "react-router-dom"

const SingleBlogCard = ({ id, explanations, blogName, blogStatus, blogDate, image }) => {


  return <div className="single-product-wrap style-bottom">
    <div className="thumb">
      <img src={image} alt="img" />
      <div className="product-wrap-details">
      </div>
    </div>
    <div className="product-details-inner">
      <h4><Link to={`/blog`}>{blogName}</Link></h4>
      <ul className="meta-inner">
        <li><Link to={`/blog`}>{blogStatus}</Link></li>
      </ul>
      <div dangerouslySetInnerHTML={{ __html: explanations }} />
      <div style={{ marginTop: "10px" }}>
        <p style={{ color: "#5ba600" }}>{blogDate}</p>
      </div>

    </div>

  </div>

}


export default SingleBlogCard