import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const Video = ({ image }) => {

	let publicUrl = process.env.PUBLIC_URL + '/'
	let IMG_URL = "https://stg.rep.start-tech.ae/"

	return <div style={{ background: "url(http://remaxnetworkhome.com/wp-content/uploads/2022/05/05_ZHA_Opus_LaurianGhinitoiu_lowres.jpg)" }} className="video-area">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-xl-8 col-lg-10">
					<div className="section-title text-center">
						{/* <img style={{ height: "20rem" }} src={IMG_URL + image?.image} /> */}
						{/* <h3 style={{ color: "#000" }}>{image?.name}</h3> */}
					</div>
				</div>
			</div>
		</div>
	</div>

}

export default Video