import React from "react";
import Navbar from "./global-components/navbar";
import Footer from "./global-components/footer";


const BlogDetailes = () => {
    return <div>
        <Navbar />
        <Footer />
    </div>
}

export default BlogDetailes