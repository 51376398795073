import React, { useState } from 'react'
import axios from '../../axios'
import APIs from '../../APIs/APIs';


const Subscibe = () => {


    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('')
    const [err_message, setErrMessage] = useState('')
    const [vEmail, setVEmail] = useState("Please enter a valid email.")


    const SubmitEmail = () => {
        setLoading(true)
        setMessage("")
        setErrMessage("")
        axios.post(APIs.Subscribe, { email }).then((res) => {
            setMessage(res.data.message)
            setEmail("")

            setLoading(false)
        }).catch(error => {
            setEmail("")
            setErrMessage(error?.response?.data?.message)
            setLoading(false)
        });

    }


    return (
        <div className="widget widget_subscribe mb-4">
            <h4 className="widget-title">SUBSCRIBE</h4>
            <div className="details">
                <div className="footer-subscribe-inner">
                    <input type="text" value={email} placeholder="Your Mail" onChange={(e) => setEmail(e.target.value)} />
                    <button disabled={loading || !/\S+@\S+\.\S+/.test(email)} className="btn btn-base" onClick={SubmitEmail}>Subscribe</button>
                </div>
                <p className='val_error'>{email.length > 1 && !/\S+@\S+\.\S+/.test(email) ? vEmail : ""}</p>
                <p className='mt-1 succus'>{message}</p>
                <p className='mt-1 val_error'>{err_message}</p>

            </div>
        </div>
    )
}

export default Subscibe