import React from "react";
import { Component } from "react";

// value = { size } setValue = { setSize } fun = { fetchTeams }
const WidgetSearch = ({ searchPlaceHolde, value, setValue, fun }) => {
    return <div className="widget-search">
        <div className="single-search-inner">
            <input type="text" placeholder={searchPlaceHolde} value={value} onChange={(e) => setValue(e.target.value)} />
            <button onClick={fun}><i className="la la-search" /></button>
        </div>
    </div>
}


export default WidgetSearch