import React, { useEffect, useState } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import SellForm from "./section-components/sellForm";
import Footer from "./global-components/footer";
import APIs from '../APIs/APIs';
import axios from '../axios'
import Loader from './section-components/Loader';


const Sell = () => {

    const [data, setDate] = useState()
    const [loading, setLoading] = useState(false)
    const fetchTeams = () => {
        setLoading(true)
        axios.get(APIs.SellPage).then((res) => {
            setDate(res.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchTeams()

    }, [])

    return (
        loading ? <Loader loading={loading} color="#fff" height="100" bg="blue" /> :
            <div>
                <PageHeader
                    headertitle="Sell or rent your property fast by listing with us"
                    headerImage="http://remaxnetworkhome.com/wp-content/uploads/2021/11/shutterstock_435578038.png"
                    extraText={data?.sellpageheader_en} />
                <SellForm data={data} />

            </div>)
}

export default Sell